html,
body,
#root {
  height: 100%;
  box-sizing: border-box;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: FinnairSans, apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #fcfbfb;
}

html {
  font-size: 16px;
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: normal;
  font-weight: 200;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Light.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Light.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Light.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: italic;
  font-weight: 200;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-LightItalic.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-LightItalic.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-LightItalic.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: normal;
  font-weight: 400;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: italic;
  font-weight: 400;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Italic.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Italic.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Italic.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: normal;
  font-weight: 600;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Medium.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Medium.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Medium.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: italic;
  font-weight: 600;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-MediumItalic.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-MediumItalic.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: normal;
  font-weight: 700;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Bold.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Bold.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Bold.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: FinnairSans;
  font-style: italic;
  font-weight: 700;
  src:
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts//FinnairSans/FinnairSans-BoldItalic.ttf') format('ttf'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts//FinnairSans/FinnairSans-BoldItalic.woff') format('woff'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts//FinnairSans/FinnairSans-BoldItalic.woff2') format('woff2');
}
